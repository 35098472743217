import { Box, Button, Divider, Menu, MenuItem } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from "react";
import { HeaderBtn, HeaderMobileBtn } from "./HeaderBtn";
import brandingIco from '../assets/icons/BRANDING.gif';
import brandingStick from '../assets/icons/BRANDING-stick.png';
import packagingIco from '../assets/icons/PACKAGING.gif';
import packaginStick from '../assets/icons/PACKAGING-stick.png';
import presentationsIco from '../assets/icons/PRESENTATIONS.gif';
import presentationsStick from "../assets/icons/PRESENTATIONS-stick.png";
import illustrationIco from '../assets/icons/ILLUSTRATION.gif';
import illustrationStick from "../assets/icons/Illustration-stick.png";
import visualmediaIco from '../assets/icons/VISUALMEDIA.gif';
import visualmediaStick from "../assets/icons/Visual-Media-stick.png";
import webIco from '../assets/icons/WEB.gif';
import webStick from "../assets/icons/WEB-stick.png";
import threedIco from '../assets/icons/3D.gif';
import threedStick from "../assets/icons/3D-stick.png";
import { styled, alpha } from '@mui/material/styles';
import Hamburger from 'hamburger-react'
import { useNavigate } from "react-router-dom";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 1,
    marginTop: theme.spacing(1),
    marginLeft: '20px',
    padding: '3px',
    // padding: '1rem',
    color: 'rgb(55, 65, 81)',
    backgroundColor: '#0e0e0e',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300],
    }),
  },
}));

export const MobileNav = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuClick = (path) => {
    setAnchorEl(null);
    navigate(path);
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="px-9 text-white"
      >
        <Hamburger color="white" size={24} toggled={open}/>
        {/* {open? <ClearIcon color="white"/> : <MenuIcon color="white"/>} */}
      </Button>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={()=>{menuClick("/branding")}}>
          <HeaderMobileBtn
            iconPath={brandingStick} 
            BtnName={'Branding'}
            href="/branding"
          />
        </MenuItem>
        <MenuItem onClick={()=>{menuClick("/packaging")}}>
          <HeaderMobileBtn
            iconPath={packaginStick} 
            BtnName={'packaging'}
            href="/packaging"
          />
        </MenuItem>
        <MenuItem onClick={()=>{menuClick("/presentations")}}>
          <HeaderMobileBtn
            iconPath={presentationsStick} 
            BtnName={'presentations'}
            href="/presentations"
          />
         
        </MenuItem>
        <MenuItem onClick={()=>{menuClick("/print-media")}}>
          <HeaderMobileBtn
            iconPath={visualmediaStick} 
            BtnName={'print media'}
            href="/print-media"
          />
        </MenuItem>
        <MenuItem onClick={()=>{menuClick("/web")}}>
          <HeaderMobileBtn
            iconPath={webStick} 
            BtnName={'Web'}
            href="/web"
          />
        </MenuItem>
        <MenuItem onClick={()=>{menuClick("/illustration")}}>
          <HeaderMobileBtn
            iconPath={illustrationStick} 
            BtnName={'illustration'}
            href="/illustration"
          />
        </MenuItem>
        <MenuItem onClick={()=>{menuClick("/threed")}}>
          <HeaderMobileBtn
            iconPath={threedStick} 
            BtnName={'3d'}
            href="/threed"
          />       
        </MenuItem>
        <MenuItem>
          <Box className="rounded-full h-[71px] flex flex-col justify-between ">
            <Box onClick={() => {navigate('/aboutus')}} className="h-[35px] w-[71px] rounded-t-full bg-[#282828] text-white hover:bg-white hover:text-black border-1 border-b-transparent flex justify-center items-center">
              <span className="text-[0.5rem] uppercase">about us</span>
            </Box>
            <Divider color="transparent" />
            <Box onClick={() => {navigate('/faq')}} className="h-[35px] w-[71px] rounded-b-full bg-[#282828] text-white hover:bg-white hover:text-black border-1 border-t-transparent flex justify-center items-center">
            <span  className="text-[0.5rem] uppercase">faq</span>
            </Box>
          </Box>
        </MenuItem>
      </StyledMenu>
    </div>
  );
}